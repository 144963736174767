@media (max-width: 1024px) {
  .box-content {
    min-height: 100px;
  }

  .big-font {
    font-size: 85px;
  }

  .landing-section {
    .mat-card {
      .mat-card-content {
        display: flex;
        flex-direction: row;
        height: inherit;

        .mat-card-inside-content {
          padding-left: 15px;
        }
      }

      .mat-card-actions {
        &.custom-card-footer {
          padding: 0 0 15px 60px;
          border-top: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);

          .learn-link {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .sidebar-navi {
    left: -100%;
    width: 100%;
  }

  .main-content.pagetoggle .sidebar-navi {
    left: 0;
    width: 100%;
  }

  .pagetoggle .sidebar-navi .navi-text {
    display: inline-block;
  }

  .humburger {
    position: static;
  }

  .mat-dialog-container {
    min-width: inherit;
    border-radius: 15px !important;

  }

  .main-content {
    padding-left: 0;
  }

  .landing-section {
    .mat-card {
      .mat-card-content {
        display: flex;
        flex-direction: row;
        height: inherit;

        .mat-card-inside-content {
          padding-left: 15px;
        }
      }

      .mat-card-actions {
        &.custom-card-footer {
          padding: 0 0 15px 60px;
          border-top: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);

          .learn-link {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .navbar-brand {
    margin-left: 30px;
  }

  .mat-sidenav-content {
    margin-right: 15px;
  }
}

@media (max-width: 990px) {
  .container {
    max-width: 100%;
  }

  .box-content {
    min-height: 160px;
  }

  .big-font {
    font-size: 60px;
  }

  .landing-section {
    .mat-card {
      .mat-card-content {
        display: flex;
        flex-direction: row;
        height: inherit;

        .mat-card-inside-content {
          padding-left: 15px;
        }
      }

      .mat-card-actions {
        &.custom-card-footer {
          padding: 0 0 15px 60px;
          border-top: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);

          .learn-link {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .mat-drawer-content:not(.landing) {
    margin-left: 170px !important;
  }

  .landing {
    margin: 0 !important;

    .container,
    .news-grid {
      padding: 30px;
    }
  }

  .pagetoggle .mat-drawer-content {
    padding-left: 25px;
  }
}

@media (max-width: 767px) {
  .row-10-grid .col-md-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .box-content {
    min-height: inherit;
  }

  .big-font {
    font-size: 50px;
    line-height: normal;
  }

  .page-empty-gap {
    height: auto;
  }

  .mat-drawer-container {
    &.main-content {
      padding-left: 0;
      top: 57px;
    }

    &.pagetoggle {
      .mat-sidenav {
        width: 150px !important;
        .navi-text {
          display: inline-block;
        }
      }
    }

    &:not(.pagetoggle) {
      .mat-sidenav {
        width: 55px !important;
        .navi-text {
          display: none;
        }
      }
    }
  }

  .no-sidenav .mat-drawer-content:not(.landing) {
    margin-left: 0 !important;
  }

  .pagetoggle .mat-drawer-content {
    margin-left: 70px !important;
    padding-left: 0;
  }

  .mat-sidenav-content:not(.landing) {
    margin-left: 70px !important;
    margin-right: 15px;
  }

  .sidebar-navi {
    left: -100%;
    width: 100%;
  }

  .main-content.pagetoggle .sidebar-navi {
    left: 0;
    width: 100%;
  }

  .pagetoggle .sidebar-navi .navi-text {
    display: inline-block;
  }

  .humburger {
    position: static;
  }

  .mat-icon-button.humburger {
    transform: rotate(180deg);
  }

  .mat-dialog-container {
    min-width: inherit;
  }

  .main-content {
    padding-left: 0;
  }

  .landing-section {
    .mat-card {
      .mat-card-content {
        display: flex;
        flex-direction: row;
        height: inherit;

        .mat-card-inside-content {
          padding-left: 15px;
        }
      }

      .mat-card-actions {
        &.custom-card-footer {
          padding: 0 0 15px 60px;
          border-top: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);

          .learn-link {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .navbar-brand {
    margin-left: 30px;
  }

  .toggle-view-list {
    display: none;
  }
}

.toggle-view-list {
  cursor: pointer;
  color: #014b76;
   margin-left: -35px;;

  li {
    list-style-type: none;
    display: inline;
    padding: 5px;
  }

  a.material-icons {
    font-size: 35px;
  }

  a.active {
    color: #f6921e;
    transform: scale(1.2);
  }
}

.toggle-view-list:hover {
  background-color: none;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, max-content));
  grid-gap: 16px;
  padding: initial;
  justify-content: center;

  &.list {
    grid-template-columns: repeat(1, 85%);

    div {
      max-width: 100%;
      width: 100%;

      .content-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100% - 26px);
        margin: auto;
        align-items: center;
        min-height: 50px;
        max-height: auto;

        .mat-card-content,
        .mat-card-actions,
        p {
          margin-bottom: 0;
          line-height: 50px;
        }
      }
    }
  }

  div {
    max-width: 270px;
  }
}

.card-search-input {
  width: 270px;
}


@media (min-width: 767px) {
  .card-search-input {
    margin-left: auto;
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .card-search-input {
    margin: auto auto;
  }
  .current-account{
    display: none !important;
  }
}

@media (max-width: 576px) {
  .mat-drawer-container {
    &.main-content {
      .mat-sidenav-content {
        padding: 0;
      }
    }
  }
  .custom-navbar {
    height: auto;
    .container {
      flex-wrap: wrap;

      .mat-icon-button.humburger {
        top: 0;
      }

      .logo {
        height: 40px;
      }
    }
  }

  .landing {
    .news-grid,
    .container {
      padding: 15px !important;
    }
  }

  .mat-drawer-container.main-content .mat-sidenav-content:not(.no-padding) {
    padding-left: 0px;
}
 
table {
  font-size: 12px;
}
}

