

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
// @import '~@ng-select/ng-select/themes/material.theme.css';
body {
  background-color: var(--bg-color);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: #606060;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

%heading-style {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 300;
}

h6 {
  @extend %heading-style;
}

h5 {
  @extend %heading-style;
}

h4 {
  @extend %heading-style;
}

h3 {
  @extend %heading-style;
}

h2 {
  font-size: 28px;
  color: var(--navy-text);
  @extend %heading-style;
}

h1 {
  @extend %heading-style;
}

p {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-text);
  margin-top: 0;

  a {
    color: var(--blue-text);
  }
}

.mat-divider {
  border-color: var(--dark-border);
}

.mat-sort-header-arrow {
  color: var(--grey-text);
}

.mat-menu-content,
.mat-card,
.mat-menu-panel,
.mat-expansion-panel,
.mat-autocomplete-panel,
.mat-dialog-container {
  background-color: var(--white-bg);

}



.container {
  max-width: 1140px;
  margin: 0 auto;
}

.container-fluid {
  max-width: 100%;
}

.link {
  font-size: 18px;
  font-weight: 700;
  color: #f6921e;
  text-decoration: none;
  cursor: pointer;
}

.learn-link {
  font-size: 18px;
}

.landing-icon {
  max-width: 40px;
  padding-bottom: 1rem;
}

.img-arrow {
  max-width: 25px;
}

.mat-card {
  color: inherit;
  line-height: 22.5px;
}

.mat-card-actions {
  &.custom-card-footer {
    border-top: 1px solid var(--dark-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-title {
  margin-top: 0;
}

.landing-section {
  .mat-card {
    text-align: center;
  }

  .mat-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    height: 100%;
  }
}

mat-sidenav.mat-drawer.mat-drawer-side {
  transition: all 250ms ease-in-out;
  z-index: 1000;
}

.no-sidenav {
  .mat-drawer-container {
    &.main-content {
      background-color: var(--white-bg);
    }
  }
}

.mat-drawer-container {
  &.main-content {
    position: absolute;
    top: 65px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--bg-color);

    &::after {
      content: "";
      position: absolute;
      height: 6px;
      width: 100%;
      top: -6px;
      left: 0;
      box-shadow: 0px 3px 6px var(--box-shadow);
      z-index: 999;
    }

    .mat-sidenav-content:not(.no-padding) {
      padding: 0 50px;
    }
  }

  .mat-drawer {
    overflow: initial;

    .mat-drawer-inner-container {
      overflow: initial;
    }
  }
}

.text-center {
  text-align: center;
}

.mat-dialog-container {
  min-width: 500px;
  border-radius: 0px !important;
  box-shadow:  0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.mat-dialog-content {
  margin-top: 15px !important;
}

.mat-drawer {
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}

.pagetoggle {
  .navi-text {
    display: none;
  }
}

.mat-sidenav {
  background-color: var(--side-nav);
  z-index: 100;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #014b76;
}

.flex-inline {
  display: inline-flex;
}

mat-icon {
  cursor: pointer;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-header-02 {
  margin: 0;
  .mat-card-header-text {
    margin: 0;
    .mat-card-title-02 {
      font-size: 11px;
      font-weight: 500;
      line-height: 15px;
    }
  }
}
.bold {
  font-weight: 500;
}
.dashboard-card {
  padding-bottom: 30px !important;
  .mat-card-header {
    .mat-card-title {
      margin-bottom: 25px;
    }
  }
}

.text-grey {
  color: #606060;
}

.blur-text {
  color: transparent !important;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.26);

  &::before,
  &::after {
    filter: blur(5px);
  }
}

#ok {
  color: #009933;
}

#warning {
  color: #e07700;
}

#error {
  color: #cc0000;
}

div,
li:focus {
  outline: none;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #e3750c !important;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #e3750c;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e3750c;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #e3750c;
  background-color: #e3750c;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #e3750c;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
  word-break: break-all !important;
  text-align: center;
}
.ng-select .ng-select-container {
  background-color: var(--input-bg) !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-color: var(--mat-menu-item-border) !important;
  background-color: var(--input-bg) !important;
  color: var(--accent-btn-text) !important;
  &:hover {
    background-color: var(--mat-menu-item-hover) !important;
  }
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: var(--mat-menu-item-hover) !important;
}

.success-snackbar {
  background-color: green;
}

.error-snackbar {
  background-color: red;
}
.ng-select .ng-select-container {
  color: var(--dark-text);
}


.shepherd-button {
  &.green-button {
    background: green;
    color: white;
    border-radius: 1rem;
  }
}


.shepherd-element, .shepherd-arrow{
background-color: #024B76 !important;

}

.shepherd-arrow:before{
  background-color: #024B76 !important;

}

.shepherd-has-title .shepherd-content .shepherd-header{
  background-color:  #024B76!important;

}

.shepherd-title{
  color: #fff !important;
  font-weight: bold !important;
}


.shepherd-text{
  color: #fff !important;
}

:host ::ng-deep .mat-progress-spinner circle, .mat-spinner circle{
  stroke: var(--loader) !important;


}

::ng-deep  .cytoscape-navigator {
  display: none;
}